import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import {store, persistor} from './store';
import * as serviceWorker from './serviceWorker';

import App from './App';
import Loading from './views/loading';

import './index.css';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1769aa",
    },
    secondary: {
      main: "#aa1768",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loading/>} persistor={persistor}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <App/>
        </MuiThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
