import React, {Component} from 'react';
import * as R from 'ramda';
import UiTab from "@material-ui/core/Tab";

class Tab extends Component {
  render() {
    if (!this.props.visible) {
      return <UiTab
        style={{display: "none"}}
        value={this.props.value}
      />;
    }
    return <UiTab
      className={"tab"}
      value={this.props.value}
      label={this.props.label}
      icon={this.props.icon}
      {...R.omit(["visible"], this.props)}
    />;
  }
}

export default Tab;
