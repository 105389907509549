import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/es/DialogContent";
import DialogTitle from "@material-ui/core/es/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

export default class DeletionDialog extends Component {
  render() {
    return (
      <div className="component">
        <Dialog
          open={true}
          onClose={this.props.cancelAction}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.cancelAction} color="primary">
              Annuler
            </Button>
            <Button onClick={this.props.confirmAction} color="secondary">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
