import React from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {
  return (
    <Grid container justify="center" className="view">
      <Grid item xs={4}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loading;
