import requestService from './request';

const pluralizedDecoratorNames = {
  shop: 'shops',
  category: 'categories',
};

export const getPluralizedDecoratorName = (decoratorName) => {
  return pluralizedDecoratorNames[decoratorName];
};

export const getDecorator = (decoratorName, id) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'GET',
    path: `/${decoratorName}/${id}`,
  })
    .then(({data}) => data);
};

export const getDecorators = (decoratorName, first, last, orderBy = 'id', order = 'asc') => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'GET',
    path: `/${decoratorName}?sort=${orderBy}%20${order.toUpperCase()}`,
    headers: {
      Range: `items=${first}-${last}`,
    }
  })
    .then(({data, headers}) => {
      const rangeHeader = headers["content-range"];
      const [first, last, count] = (rangeHeader.match(/items ([0-9]*)-([0-9]*)\/([0-9]*)/) || []).slice(1);
      return {
        items: data,
        first: Number(first),
        last: Number(last),
        count: Number(count),
      };
    });
};

export const getDecoratorsWithoutPagination = (decoratorName, _first = 0, limit = 9, data = []) => (dispatch, getState) => {
  return getDecorators(decoratorName, _first, _first + limit)(dispatch, getState)
    .then(({items, count, first, last}) => {
      data.push(...items);
      if (last < count - 1) {
        return getDecoratorsWithoutPagination(decoratorName, last + 1, _first + limit, data)(dispatch, getState);
      }
      dispatch({type: `SET_${decoratorName.toUpperCase()}_TABLE`, decoratorName, items: data});
      dispatch({type: `SET_${decoratorName.toUpperCase()}_LIST`, decoratorName, items: data});
      return {
        items: data
      };
    })
};

export const createDecorator = (decoratorName, data) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'POST',
    path: `/${decoratorName}`,
    data,
  })
    .then(({data}) => data);
};

export const updateDecorator = (decoratorName, data, id) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'PUT',
    path: `/${decoratorName}/${id}`,
    data,
  })
    .then(({data}) => data);
};

export const deleteDecorator = (decoratorName, id) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'DELETE',
    path: `/${decoratorName}/${id}`,
  })
    .then(({data}) => data);
};
