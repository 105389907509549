import 'date-fns';
import React, {Component} from 'react';
import moment from "moment";
import * as R from "ramda";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from 'material-ui-pickers';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons';
import CreatableSelect from 'react-select/lib/Creatable';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {createDecorator, getDecoratorsWithoutPagination} from "../services/decorator"

const checkAmount = (amount, allowEmpty = false) => (allowEmpty || !R.isEmpty(amount)) && !isNaN(amount);

class OperationForm extends Component {
  state = {
    amount: "",
    description: "",
    selectedCategory: null,
    selectedShop: null,
    backToOperationList: false,
    marked: false,
    amountError: false,
  };

  handleChange = name => event => {
    if (name === "amount") {
      return this.setState({
        amountError: false,
        amount: event.target.value.replace(/,/, '.'),
      });
    }
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange = date => {
    this.setState({date});
  };

  handleShopChange = (selectedShop) => {
    this.setState({selectedShop});
    if (R.path(['__isNew__'], selectedShop)) {
      return this.props.createDecorator('shop', {name: selectedShop.label})
        .then(({id}) => this.setState({selectedShop: R.assoc('id', id, selectedShop)}));
    }
  };

  handleCategoryChange = (selectedCategory) => {
    this.setState({selectedCategory});
    if (R.path(['__isNew__'], selectedCategory)) {
      return this.props.createDecorator('category', {name: selectedCategory.label})
        .then(({id}) => this.setState({selectedCategory: R.assoc('id', id, selectedCategory)}));
    }
  };

  submitForm() {
    const {amount} = this.state;
    if (!checkAmount(amount, false)) {
      this.setState({amountError: true});
    } else {
      this.props.onSubmit(R.clone(this.state));
      this.setState({
        amount: "",
        description: "",
        selectedCategory: null,
        selectedShop: null,
      })
    }
  }

  componentDidMount() {
    this.props.getDecoratorsWithoutPagination('category');
    this.props.getDecoratorsWithoutPagination('shop');

    const consolidatedProps = R.assoc('amount', this.props.amount ? Math.abs(this.props.amount) : '', this.props);
    this.setState(R.pick(['id', 'amount', 'date', 'description', 'selectedCategory', 'selectedShop', 'shops', 'categories', 'onSubmit'], consolidatedProps));
  }

  render() {
    const {id, amount, description, date, selectedCategory, selectedShop, backToOperationList, marked} = this.state;
    const {shops, categories} = this.props;
    return (
      <div className="component">
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                margin="normal"
                label="Date"
                leftArrowIcon={<KeyboardArrowLeft/>}
                rightArrowIcon={<KeyboardArrowRight/>}
                value={date}
                labelFunc={date => date === null ? '' : moment(date).format('DD/MM/YYYY')}
                onChange={this.handleDateChange}
                variant="outlined"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={this.state.amountError || !checkAmount(amount, true)}
              id="outlined-amount"
              label="Montant"
              value={amount}
              onChange={this.handleChange('amount')}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            id="outlined-description"
            label="Description"
            value={description}
            onChange={this.handleChange('description')}
            margin="normal"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid container spacing={16} className="selectContainer">
          <Grid item xs={6} className="select">
            <CreatableSelect
              id="category"
              placeholder={"Catégorie"}
              value={selectedCategory}
              formatCreateLabel={(inputValue) => `Ajouter la catégorie "${inputValue}"`}
              onChange={this.handleCategoryChange}
              options={categories}
              isClearable
            />
          </Grid>
          {this.props.isExpense === true &&
          <Grid item xs={6} className="select">
            <CreatableSelect
              id="shop"
              placeholder={"Magasin"}
              value={selectedShop}
              formatCreateLabel={(inputValue) => `Ajouter le magasin "${inputValue}"`}
              onChange={this.handleShopChange}
              options={shops}
              isClearable
            />
          </Grid>
          }
        </Grid>
        <Grid container direction="row" justify="space-between">
          <FormControlLabel
            control={
              <Checkbox
                checked={marked}
                onChange={(event) => this.setState({marked: event.target.checked})}
              />
            }
            label={`Pointer cette opération`}
          />
          {!id &&
          <FormControlLabel
            control={
              <Checkbox
                checked={backToOperationList}
                onChange={(event) => this.setState({backToOperationList: event.target.checked})}
              />
            }
            label={`Revenir à la liste`}
          />
          }
        </Grid>
        <Grid container direction="column">
          <Button variant="contained" color="primary" className="button" onClick={() => this.submitForm()}>
            {id ? "Enregistrer" : "Ajouter"}
          </Button>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.decorator.category.list,
    shops: state.decorator.shop.list,
  };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  createDecorator,
  getDecoratorsWithoutPagination,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OperationForm);
