import * as R from 'ramda';

const initialState = {
};

const ToolReducer = function (previousState = initialState, action) {
  const newState = R.clone(previousState);
  switch (action.type) {
    case 'FORCE_REFRESH':
      return R.assoc('forceRefresh', Math.random(), newState);
    default:
      return previousState;
  }
};

export default ToolReducer;
