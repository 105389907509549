import 'date-fns';
import React, {Component} from 'react';
import * as R from "ramda";
import SearchableSelect from 'react-select';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {getDecoratorsWithoutPagination} from "../services/decorator";
import {getOperationsWithoutPagination, getFavoritesWithoutPagination} from "../services/operation";

class FavoriteSelector extends Component {
  state = {
    selectedFavorite: null,
  };

  componentDidMount() {
    this.props.getDecoratorsWithoutPagination('category');
    this.props.getDecoratorsWithoutPagination('shop');
    this.props.getFavoritesWithoutPagination(0, 49, {favorite: true});
  }

  handleFavoriteSelect = (fav) => {
    this.setState({selectedFavorite: fav}, () => {
      this.props.onChange(fav);
    });
  };

  render() {
    const {selectedFavorite} = this.state;
    return (
      <div className="select">
        <SearchableSelect
          id="favorite"
          placeholder={"Copier un favori"}
          value={selectedFavorite}
          onChange={this.handleFavoriteSelect}
          options={this.props.favorites}
          isClearable
          maxMenuHeight={150}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    favorites: R.map(({id, amount, description, category, shop}) => {
      const value = description
        || state.decorator.category.table[category]
        || state.decorator.shop.table[shop];
      return {
        id, amount, category, shop, value, label: `${value} (${amount}€)`
      }
    }, state.operation.favorites),
    categories: state.decorator.category.table,
    shops: state.decorator.shop.table,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getDecoratorsWithoutPagination,
  getFavoritesWithoutPagination,
  getOperationsWithoutPagination,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteSelector);
