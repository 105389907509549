import React, { Component } from 'react';

import Grid from "@material-ui/core/Grid";
import {withRouter} from 'react-router-dom';

import DecoratorOverview from "../components/decoratorOverview";

class ShopOverview extends Component {
  render() {
    return (
      <Grid container direction="column" className="view">
        <Grid container justify="center">
          <Grid item xs={6}>
            <DecoratorOverview type="shop" id={this.props.match.params.id}/>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ShopOverview);
