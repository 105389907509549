import {persistStore, persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { applyMiddleware, createStore} from 'redux';

import rootReducer from './reducers';
import thunk from "redux-thunk";
import logger from "redux-logger";

const persistConfig = {
  key: 'money_io',
  storage
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  undefined,
  applyMiddleware(thunk, logger),
);

export const persistor = persistStore(store);