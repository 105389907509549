import * as R from 'ramda';

const initialState = {
  category: {
    list: [],
    table: {},
  },
  shop: {
    list: [],
    table: {},
  },
};

const DecoratorReducer = function (previousState = initialState, action) {
  const newState = R.clone(previousState);
  switch (action.type) {
    case 'CLEAR_DECORATOR':
      return initialState;
    case 'SET_CATEGORY_TABLE':
    case 'SET_SHOP_TABLE':
      const table = {};
      action.items.forEach(({id, name}) => table[id] = name);
      return R.pipe(
        R.assocPath([action.decoratorName, 'table'], table)
      )(newState);
    case 'SET_CATEGORY_LIST':
    case 'SET_SHOP_LIST':
      return R.pipe(
        R.assocPath([action.decoratorName, 'list'], action.items.map(({id, name}) => ({id, value: name, label: name})))
      )(newState);
    default:
      return previousState;
  }
};

export default DecoratorReducer;
