import axios from "axios";
import moment from "moment";
import * as P from "bluebird";
import * as R from "ramda";
import Config from "./config";

const request = ({state, dispatch}, {method, path, headers, data}, authenticated = true) => {
  const {accessToken, accessTokenExpirationDate} = state.session;
  return P.resolve()
    .then(() => {
      if (moment().diff(accessTokenExpirationDate) > 0) {
        return axios({
          method: 'POST',
          url: `${Config.host}/token/refresh`,
          data: {
            grant_type: 'refresh_token',
            refresh_token: state.session.refreshToken,
          }
        })
          .then(({data}) => {
            dispatch({
              type: 'REGISTER_TOKEN',
              accessToken: data.access_token,
              accessTokenExpirationDate: moment().add(data.expires_in, 'seconds').toISOString(),
              refreshToken: data.refresh_token,
            });
            return data.access_token;
          })
          .catch(() => {
            dispatch({type: 'REVOKE_TOKEN'});
            dispatch({type: 'CHANGE_TAB', path: "/login"});
          });
      }
    })
    .then((newAccessToken) => axios({
      method: method,
      url: `${Config.host}${path}`,
      headers: authenticated ? R.mergeAll([{Authorization: newAccessToken || accessToken}, headers]) : headers,
      data,
    }))
    .catch((error) => {
      if (R.path(["response", "status"], error) === 403) {
        dispatch({type: 'REVOKE_TOKEN'});
        dispatch({type: 'CHANGE_TAB', path: "/login"});
      }
    });
};

export default request;