import React, {Component} from 'react';

import {Switch, Redirect, Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

import Menu from "./components/menu";
import AddOperation from "./views/addOperation";
import CategoryOverview from "./views/categoryOverview";
import EditCategory from "./views/editCategory";
import EditOperation from "./views/editOperation";
import EditShop from "./views/editShop";
import Login from "./views/login";
import MyCategories from "./views/myCategories";
import MyOperations from "./views/myOperations";
import MyFavorites from "./views/myFavorites";
import MyShops from "./views/myShops";
import SignUp from "./views/signup";
import ShopOverview from "./views/shopOverview";

class App extends Component {

  render() {
    const {isConnected} = this.props;
    const redirectionPath = isConnected ? "/operations/list" : "/login";
    return (
      <div className="root">
        <Menu/>
        {isConnected &&
        <Switch>
          <Route path='/operations/list' component={MyOperations}/>
          <Route path='/operations/edit/:id' component={EditOperation}/>
          <Route path='/operations/edit' component={AddOperation}/>
          <Route path='/favorites/list' component={MyFavorites}/>
          <Route path='/categories/list' component={MyCategories}/>
          <Route path='/categories/edit/:id' component={EditCategory}/>
          <Route path='/categories/view/:id' component={CategoryOverview}/>
          <Route path='/categories/edit' component={EditCategory}/>
          <Route path='/shops/list' component={MyShops}/>
          <Route path='/shops/edit/:id' component={EditShop}/>
          <Route path='/shops/view/:id' component={ShopOverview}/>
          <Route path='/shops/edit' component={EditShop}/>
          <Redirect to={redirectionPath}/>
        </Switch>
        }
        {!isConnected &&
        <Switch>
          <Route path='/login' component={Login}/>
          <Route path='/signup' component={SignUp}/>
          <Redirect to={redirectionPath}/>
        </Switch>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isConnected: !!state.session.accessToken,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
