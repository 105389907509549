import React, {Component} from 'react';
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {StarBorder, Star, Edit, CheckBoxOutlineBlank, CheckBox, DeleteOutline, MoreVert} from '@material-ui/icons';

class OperationMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleItemClick = action => () => {
    if (action === 'delete') {
      this.setState({anchorEl: null});
    }
    this.props[`${action}Action`]();
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const {anchorEl} = this.state;
    const {operation} = this.props;
    return (
      <div>
        <IconButton onClick={this.handleClick}>
          <MoreVert/>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleItemClick('edit')}>
            <ListItemIcon>
              <Edit fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Modifier</ListItemText>
          </MenuItem>
          <MenuItem onClick={this.handleItemClick('delete')}>
            <ListItemIcon>
              <DeleteOutline fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Supprimer</ListItemText>
          </MenuItem>
          {operation.favorite &&
          <MenuItem onClick={this.handleItemClick('fav')}>
            <ListItemIcon>
              <Star fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Retirer des favoris</ListItemText>
          </MenuItem>
          }
          {!operation.favorite &&
          <MenuItem onClick={this.handleItemClick('fav')}>
            <ListItemIcon>
              <StarBorder fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Définir comme favori</ListItemText>
          </MenuItem>
          }
          {!operation.marked &&
          <MenuItem onClick={this.handleItemClick('mark')}>
            <ListItemIcon>
              <CheckBoxOutlineBlank fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Pointer l'opération</ListItemText>
          </MenuItem>
          }
          {operation.marked &&
          <MenuItem onClick={this.handleItemClick('mark')}>
            <ListItemIcon>
              <CheckBox fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Retirer le pointage</ListItemText>
          </MenuItem>
          }
        </Menu>
      </div>
    );
  }
}

export default OperationMenu;