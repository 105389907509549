import moment from 'moment';
import * as P from 'bluebird';

import requestService from './request';

export const requestAccessToken = (email, password) => (dispatch, getState) => {
  return requestService({state: getState(), dispatch}, {
    method: 'POST',
    path: `/token`,
    data: {
      email, password
    }
  }, false)
    .then(({data}) => P.all([
        dispatch({
          type: 'REGISTER_TOKEN',
          accessToken: data.access_token,
          accessTokenExpirationDate: moment().add(data.expires_in, 'seconds').toISOString(),
          refreshToken: data.refresh_token,
        }),
        dispatch({type: 'CHANGE_TAB', path: "/operations/list"}),
      ])
    );
};

export const disconnect = () => dispatch => P.all([
  dispatch({type: 'REVOKE_TOKEN'}),
  dispatch({type: 'CLEAR_DECORATOR'}),
  dispatch({type: 'CLEAR_OPERATION'}),
  dispatch({type: 'CHANGE_TAB', path: "/login"}),
]);
