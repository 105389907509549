import DecoratorReducer from "./decorator";
import NavigationReducer from "./navigation";
import OperationReducer from "./operation";
import SessionReducer from "./session";
import ToolReducer from "./tool";

export default {
  navigation: NavigationReducer,
  decorator: DecoratorReducer,
  operation: OperationReducer,
  session: SessionReducer,
  tool: ToolReducer,
};
