import React, {Component} from 'react';
import * as R from 'ramda';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {createDecorator, getDecorator, updateDecorator, getPluralizedDecoratorName} from "../services/decorator";

const checkName = (name, allowEmpty = false) => (allowEmpty || !R.isEmpty(name));

class DecoratorForm extends Component {
  state = {
    name: "",
    description: "",
    decoratorName: this.props.type,
    currentDecoratorId: this.props.id,
    nameError: false,
  };

  createDecorator() {
    if (!checkName(this.state.name, false)) {
      this.setState({nameError: true});
    } else {
      const data = R.pick(['name', 'description'], this.state);
      return this.props.createDecorator(this.state.decoratorName, data)
        .then((decorator) => {
          this.props.history.push(`/${getPluralizedDecoratorName(this.state.decoratorName)}/list?created=${decorator.id}`)
        });
    }
  }

  updateDecorator(id) {
    if (!checkName(this.state.name, false)) {
      this.setState({nameError: true});
    } else {
      const data = R.pick(['name', 'description'], this.state);
      return this.props.updateDecorator(this.state.decoratorName, data, id)
        .then((decorator) => {
          this.props.history.push(`/${getPluralizedDecoratorName(this.state.decoratorName)}/list?updated=${decorator.id}`)
        });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentDidMount() {
    const {currentDecoratorId, decoratorName} = this.state;
    if (currentDecoratorId) {
      return this.props.getDecorator(decoratorName, currentDecoratorId)
        .then((decorator) => {
          this.setState({
            name: decorator.name,
            description: decorator.description,
          });
        });
    }
  }

  render() {
    const {name, description, currentDecoratorId, nameError} = this.state;
    return (
      <div className="component">
        <Grid container justify="center">
          <Grid item xs={6}>
            <Grid container direction="column">
              <Grid item>
                <TextField
                  id="outlined-name"
                  label="Name"
                  value={name}
                  onChange={this.handleChange('name')}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  error={nameError || !checkName(name, true)}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-description"
                  label="Description"
                  value={description}
                  onChange={this.handleChange('description')}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => currentDecoratorId ? this.updateDecorator(currentDecoratorId) : this.createDecorator()}
                  >
                    {currentDecoratorId ? 'Enregistrer' : 'Ajouter'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createDecorator,
  getDecorator,
  updateDecorator,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(DecoratorForm));

