import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import OperationList from "../components/operationList";
import {getOperations, setPredicate} from "../services/operation";
import * as R from "ramda";

class MyFavorites extends Component {

  componentDidUpdate(prevProps) {
    const {page, rowsPerPage, predicate, sort} = this.props;
    const predicateChange = !R.equals(prevProps.predicate, predicate);
    const rowsPerPageChange = !R.equals(prevProps.rowsPerPage, rowsPerPage);
    const pageChange = !R.equals(prevProps.page, page);
    if (predicateChange || rowsPerPageChange || pageChange) {
      this.props.getOperations(page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1, predicate, sort);
    }
  }

  componentDidMount() {
    this.props.setPredicate({favorite: true});
  }

  render() {
    return (
      <Grid container justify="center" className="view">
        <Grid item lg={10} md={12}>
          <OperationList/>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    predicate: state.operation.predicate,
    sort: state.operation.meta.sort,
    page: state.operation.meta.page || 0,
    rowsPerPage: state.operation.meta.rowsPerPage || 5,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getOperations,
  setPredicate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyFavorites);
