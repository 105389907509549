import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";

export default class SignUp extends Component {
  render() {
    return (
      <div className="view">
        <Grid container spacing={16}>
          <Grid item xs={6}>
            SIGN UP
          </Grid>
        </Grid>
      </div>
    );
  }
}
